












export default {
  name: 'TextBanner'
}
